<div class="header">
  <nav class="navbar navbar-expand-sm navbar-dark">
    <a class="navbar-brand order-1" routerLink="/">
      <img class="d-block mx-auto my-1 img-logo" src="./assets/symbol_white.svg"
        onerror="this.src='./assets/BriteLogo.png'" />
      <div class="d-block text-logo">Demo eStore</div>
    </a>

    <button class="navbar-toggler ml-auto order-2" type="button" data-toggle="collapse"
      data-target="#collapsibleNavbar">
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="order-3 order-sm-4 pl-3 pr-3" routerLink="/cart">
      <i class="fa fa-3x badge" [attr.value]="itemsCount">&#xf07a;</i>
    </a>

    <div class="collapse navbar-collapse order-4 order-sm-3" id="collapsibleNavbar">
      <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/">Home</a>
        <a class="nav-item nav-link" routerLink="/purchases">My purchases</a>
      </div>
    </div>
  </nav>
</div>