<div class="container">
  <div class="row">
    <div class="p-3">
      <h4 style="text-align: center;">Shopping cart</h4>

      <div class="row d-none d-md-block border-bottom">
        <div class="d-flex">
          <div class="col-sm-3 offset-sm-2">
            <label><strong>Product</strong></label>
          </div>
          <div class="col-sm-4 options">
            <label><strong>Color</strong></label>
            <label><strong>Amount</strong></label>
          </div>
          <div class="col-sm-2">
            <label><strong>Total</strong></label>
          </div>
        </div>
      </div>

      <div *ngIf="products">
        <ul class="checkout-contents">
          <li class="checkout-item">
            <div class="row d-flex" *ngFor="let product of products">
              <div class="col-xs-5 col-sm-2">
                <div class="d-flex align-items-center justify-content-center img-shopping-cart-box">
                  <a routerLink="/product/{{product.product_id}}">
                    <div class="d-flex align-items-center justify-content-center img-shopping-cart-box">
                      <img class="img-shopping-cart" src="{{product.image}}" alt="Product image" />
                    </div>
                  </a>
                </div>
              </div>
              <div class="col-xs-5 col-sm-3 item-description">
                <span>{{product.product_title}}</span>
              </div>
              <div class="col-xs-7 col-sm-4">
                <div class="d-flex">
                  <div class="item-size">
                    <div class="select select-block">
                      <select class="custom-select">
                        <option selected>Choose...</option>
                        <option value="white">White</option>
                        <option value="blue">Blue</option>
                        <option value="red">Red</option>
                      </select>
                    </div>
                  </div>
                  <div class="item-quantity">
                    <div class="select select-block">
                      <select class="custom-select">
                        <option value="1" selected="'selected'">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">More</option>
                      </select>
                    </div>
                    <input type="text" class="form-control" hidden value="1" />
                  </div>
                </div>
              </div>
              <div class="col-xs-7 col-sm-2 item-total">
                <span>{{addSpacesToPrice(product.price.slice(0, -3))}}</span>
              </div>
              <div class="col-sm-1 item-remove hidden-xs">
                <a class="minicart-item-remove general-item-remove"
                  style="position: static; top: initial; right: initial" (click)="removeFromCart(product.product_id)">
                  <i class="fa fa-trash" style="font-size: 20px; color: black"></i>
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="col mb-1">
        <div class="row">
          <div class="col-lg-6 offset-lg-3">
            <div class="p-0,5">
              <ul class="list-unstyled mb-1">
                <li class="d-flex justify-content-between py-2 border-bottom">
                  <div class="text-muted">Products Subtotal</div>
                  {{addSpacesToPrice(subtotal_price)}} sek
                </li>
                <li class="d-flex justify-content-between py-2 border-bottom">
                  <div class="text-muted">Shipping</div>
                  {{shipping_price}} sek
                </li>
                <li class="d-flex justify-content-between py-2 border-bottom">
                  <strong class="text-muted">Total</strong>
                  <h5 class="font-weight-bold">{{addSpacesToPrice(total_price)}} sek</h5>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="product-button-container col-lg-6 offset-lg-3 mb-5 my-3 py-2">
          <button class="btn btn-outline-primary btn-sm btn-block cart-flush" (click)="clearCart()">
            <strong>Clear Cart</strong>
          </button>
          <a routerLink="/checkout" class="btn btn-outline-primary btn-sm btn-block"
            (click)="buyTotal()"><strong>Checkout</strong></a>
        </div>
      </div>
    </div>
  </div>
</div>