<div class="container admin-dashboard">
    <div class="row my-4">
        <div class="row col-lg-12">
            <div class="select-container col-lg-12">
                <div class="select-environment-paragraph">
                    <p class="paragraph-header text-center">Environment:</p>
                </div>
                <div *ngIf="environments" class="d-flex align-items-center justify-content-center">
                    <select class="form-select" [(ngModel)]="currentEnvironmentId" (change)="setEnvironment($event)">
                        <option *ngFor="let env of environments" [value]="env.id">
                            {{getEnvironmentName(env.id)}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row col-lg-12">
            <div class="select-container col-lg-12">
                <div class="select-style-paragraph">
                    <p class="paragraph-header text-center">Style:
                    </p>
                </div>
                <div *ngIf="styles" class="d-flex align-items-center justify-content-center">
                    <select class="form-select" [(ngModel)]="currentStyleId" (change)="setStyle($event)">
                        <option *ngFor="let style of styles" [value]="style.id">
                            {{getStyleName(style.id)}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row col-lg-12">
            <div class="select-container col-lg-12">
                <div class="select-style-paragraph">
                    <p class="paragraph-header text-center">Clear the list of orders:
                    </p>
                </div>
                <div class="clear-button-container col-sm-12 d-flex align-items-center justify-content-center">
                    <button class="btn btn-outline-primary btn-sm btn-block cart-flush justify-content-center"
                        (click)="clearOrderList()">
                        <strong>Clear Orders</strong>
                    </button>
                </div>
            </div>
        </div>


    </div>
</div>