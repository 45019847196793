import { Component, OnInit } from '@angular/core';
import { EnvironmentService } from '../services/environment.service';
import { Environment, Environments } from '../services/environment';
import { StyleService } from '../services/style.service';
import { Styles, Style } from '../services/style';
import { DataService } from '../services/data.service';
import { PurchasesService } from '../services/purchases.service';
import { Order, Orders } from '../services/order';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  environments: Environment[];
  currentEnvironmentId: number = 1;
  styles: Style[];
  currentStyleId: number = 1;
  orders: Orders;

  constructor(
    private environmentService: EnvironmentService,
    private styleService: StyleService,
    private dataService: DataService,
    private purchasesService: PurchasesService, ) { }

  ngOnInit(): void {
    this.getEnvironments();
    this.getStyles();
  }

  getEnvironments(): void {
    this.environmentService.getEnvironments()
      .subscribe((data: Environments) => {
        this.environments = data.environments;
        this.environments.sort((e1, e2) => e1.id - e2.id);
        console.log(this.environments);
      });
  }

  getEnvironmentName(id: number): string {
    switch (id) {
      case 1:
        return 'Sandbox';
      case 2:
        return 'Testing';
      case 3:
        return 'Staging';
      case 4:
        return 'Production';
      default:
        throw 'Invalid environment ID';
    }
  }

  setEnvironment(event) {
    this.currentEnvironmentId = event.target.value;
    console.log("Current environment ID: " + this.currentEnvironmentId);
    this.dataService.changeEnvironmentId(this.currentEnvironmentId);
  }

  getStyles(): void {
    this.styleService.getStyles()
      .subscribe((data: Styles) => {
        this.styles = data.styles;
        this.styles.sort((e1, e2) => e1.id - e2.id);
        console.log(this.styles);
      });
  }

  getStyleName(id: number): string {
    switch (id) {
      case 1:
        return 'Brite';
      case 2:
        return 'Purple';
      case 3:
        return 'Cappuccino';
      case 4:
        return 'Orange';
      default:
        throw 'Invalid style ID';
    }
  }

  setStyle(event) {
    this.currentStyleId = event.target.value;
    console.log("Current style ID: " + this.currentStyleId);
    this.dataService.changeStyleId(this.currentStyleId);
  }


  clearOrderList(): void {
    console.log('Clear Orders');
    this.purchasesService.clearOrders()
      .subscribe((data: Orders) => {
        console.log(data);
        this.orders = data;
      });
  }

}
