import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { CartComponent } from './components/cart/cart.component';
import { HomeComponent } from './components/home/home.component';
import { ProductComponent } from './components/product/product.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PurchasesComponent } from './components/purchases/purchases.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { RefundsComponent } from './components/refunds/refunds.component';
import { WithdrawalComponent } from './components/withdrawal/withdrawal.component';
import { ReturnConfirmationComponent } from './components/return-confirmation/return-confirmation.component';
import { AdminComponent } from './components/admin/admin.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    CartComponent,
    HomeComponent,
    ProductComponent,
    CheckoutComponent,
    PurchasesComponent,
    ConfirmationComponent,
    RefundsComponent,
    WithdrawalComponent,
    ReturnConfirmationComponent,
    AdminComponent,
  ],
  imports: [BrowserModule, FormsModule, HttpClientModule, AppRoutingModule, NgbModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
