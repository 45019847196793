import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../services/product.service';
import { Product, Products } from '../services/products';
import { DataService } from '../services/data.service';



@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit {
  product: Product;

  price: number;

  constructor(
    private route: ActivatedRoute,
    private productService: ProductService,
    private dataService: DataService,
    private router: Router
  ) { }


  ngOnInit(): void {
    this.getProductDetails();
  }


  getProductDetails(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.productService.getProductDetails(id)
      .subscribe((data: any) => this.product = {
        product_id: data.product_id,
        product_title: data.product_title,
        description: data.description,
        price: data.price,
        image: data.image,
        quantity: data.guantity
      });
  }



  quantity = 1;

  plus() {
    this.quantity++;
  }

  minus() {
    if (this.quantity > 1) {
      this.quantity--;
    }
  }

  buyNow(product_id: number, price: number): void {
    console.log('addToCart + buyNow');
    this.addToCart(product_id);
    this.dataService.changePrice(price);
    this.router.navigateByUrl('/checkout');
  }

  addToCart(product_id: number): void {
    console.log(`addToCart id: ${product_id.toString()}`);
    this.productService.addToCart(product_id)
      .subscribe((response) => {
        this.getItemsInCart();
      });
  }

  getItemsInCart(): void {
    this.productService.getProductsInCart()
      .subscribe((data: any) => {
        this.dataService.changeItemsCount(data.length);
      });
  }

  addSpacesToPrice(price: number): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
