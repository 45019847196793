import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ProductService } from '../services/product.service';
import { Products } from '../services/products';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  title = 'Special Deals';

  response: Products;

  price: number;

  subscription: Subscription;

  constructor(private productService: ProductService, private dataService: DataService, private router: Router) { }

  ngOnInit(): void {
    this.getProducts();
    this.subscription = this.dataService.currentPrice.subscribe((price) => this.price = price);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getProducts(): void {
    this.productService.getProducts()
      .subscribe((data: Products) => this.response = { ...data });
  }

  buyNow(product_id: number): void {
    this.addToCart(product_id);
    this.router.navigateByUrl('/cart');
  }

  addToCart(product_id: number): void {
    console.log(`addToCart id: ${product_id.toString()}`);
    this.productService.addToCart(product_id)
      .subscribe((response) => {
        this.getItemsInCart();
      });
  }

  getItemsInCart(): void {
    this.productService.getProductsInCart()
      .subscribe((data: any) => {
        this.dataService.changeItemsCount(data.length);
      });
  }

  addSpacesToPrice(price: number): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}





