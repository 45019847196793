import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private priceSource = new BehaviorSubject(0);

  private itemsCountSource = new BehaviorSubject(0);

  private orderReturnSource = new BehaviorSubject(0);

  private styleIdSource = new BehaviorSubject(1);

  private environmentIdSource = new BehaviorSubject(1);

  currentPrice = this.priceSource.asObservable();

  currentItemsCount = this.itemsCountSource.asObservable();

  currentOrderReturn = this.orderReturnSource.asObservable();

  currentStyleId = this.styleIdSource.asObservable();

  currentEnvironmentId = this.environmentIdSource.asObservable();

  constructor() { }

  changePrice(price: number) {
    this.priceSource.next(price);
  }

  changeItemsCount(itemsCount: number) {
    this.itemsCountSource.next(itemsCount);
  }

  changeOrderReturn(orderReturn: number) {
    this.orderReturnSource.next(orderReturn);
  }

  changeStyleId(styleId: number) {
    this.styleIdSource.next(styleId);
  }

  changeEnvironmentId(environmentId: number) {
    this.environmentIdSource.next(environmentId);
  }
}
