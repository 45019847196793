import { Component, OnInit } from '@angular/core';
import { ProductService } from '../services/product.service';
import { Product } from '../services/products';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  products: Product[];

  subtotal_price = 0;

  shipping_price = 0;

  total_price = 0;

  constructor(private productService: ProductService, private dataService: DataService) { }

  calculatePrices(): void {
    if (this.products.length > 0) {
      this.shipping_price = 49;
    } else {
      this.shipping_price = 0;
    }
    this.subtotal_price = this.products.reduce((s, product) => s + +product.price, 0);
    this.total_price = this.subtotal_price + this.shipping_price;
  }

  ngOnInit(): void {
    this.getProductsInCart();
  }

  getProductsInCart(): void {
    this.productService.getProductsInCart()
      .subscribe((data: any) => {
        this.products = data;
        this.calculatePrices();
        this.dataService.changeItemsCount(this.products.length);
      });
  }

  removeFromCart(product_id: number): void {
    console.log(`removeFromCart id: ${product_id.toString()}`);
    this.productService.removeFromCart(product_id)
      .subscribe((response: any) => {
        this.products.splice(this.products.findIndex((product) => product.product_id == product_id), 1);
        this.calculatePrices();
        this.dataService.changeItemsCount(this.products.length);
      });
  }

  clearCart(): void {
    console.log('Clear Cart');
    this.productService.flushCart()
      .subscribe((response: any) => {
        this.products = [];
        this.calculatePrices();
        this.dataService.changeItemsCount(this.products.length);
      });
  }

  buyTotal(): void {
    console.log('buyTotal');
    this.dataService.changePrice(this.total_price);
  }

  addSpacesToPrice(price: number): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
}
