<div class="container">
    <div class="row">
        <div class="p-3 purchases-page">
            <div class="p-2 purchases-header">
                <h4 style="text-align: center;">My purchases</h4>
            </div>
            <div class="row d-none d-md-block border-bottom">
                <div class="d-flex">
                    <div class="col-sm-2">
                        <label><strong>Order</strong></label>
                    </div>
                    <div class="col-sm-3 offset-sm-2">
                        <label><strong>Products</strong></label>
                    </div>
                    <div class="col-sm-1 options">
                        <label><strong>Amount</strong></label>
                    </div>
                    <div class="col-sm-2">
                        <label><strong>Price, kr</strong></label>
                    </div>
                    <div class="col-sm-2" style="text-align: center;">
                        <label><strong>Return</strong></label>
                    </div>
                </div>
            </div>

            <div *ngIf="orders">
                <ul class="purchases-content">
                    <li class="purchases-item border-bottom">
                        <div class="row d-flex border-bottom" *ngFor="let order of orders | keyvalue: keyDescOrder">

                            <div class="order-datum colomn col-sm-2">
                                <div class="row order">
                                    <div class="col-sm-12">
                                        <span>{{order.key}}</span>
                                    </div>
                                </div>
                                <div class="row datum">
                                    <div class="col-sm-12">
                                        <span>{{order.value.created.slice(0, -13)}}</span>
                                    </div>
                                </div>
                                <div class="row status">
                                    <div class="col-sm-12 order-status">
                                        <strong><span>{{convertOrderStatus(order.value.status)}}</span></strong>
                                    </div>
                                </div>
                            </div>

                            <div class="order-details colomn col-sm-8">
                                <div class="row d-flex" *ngFor="let item of order.value.items">
                                    <div class="col-xs-5 col-sm-3">
                                        <a routerLink="/product/:id">
                                            <div
                                                class="d-flex align-items-center justify-content-center img-shopping-cart-box">
                                                <img class="img-shopping-cart" src="{{item.image}}"
                                                    alt="Product image" />
                                            </div>
                                        </a>
                                    </div>
                                    <div class="col-xs-5 col-sm-5 item-description">
                                        <span>{{item.title}}</span>
                                    </div>
                                    <div class="col-xs-2 item-remove visible-xs">
                                        <a class="minicart-item-remove general-item-remove"
                                            style="position: static; top: initial; right: initial"><i
                                                class="icon icon-return"></i></a>
                                    </div>
                                    <div class="col-xs-6 col-sm-1">
                                        <div class="item-quantity" style="text-align: center;">
                                            <span>{{item.quantity}}</span>
                                        </div>
                                    </div>
                                    <div class="col-xs-6 col-sm-3 item-total">
                                        <span>{{addSpacesToPrice(item.price.slice(0, -3))}}</span>
                                    </div>
                                </div>
                                <div class="total-price">
                                    <div class="row">
                                        <div class="col-sm-3 offset-sm-9">
                                            <span><strong>{{addSpacesToPrice(calculateOrderPrices(order.key))}}</strong></span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="status-return column col-sm-2">
                                <div class="row">
                                    <div class="col-sm-12 item-remove hidden-xs">
                                        <div class="return-button" *ngIf="order.value.status == 2">
                                            <a routerLink="/refunds" class="btn btn-outline-primary btn-sm btn-block"
                                                (click)="passOrderId(order.key)"><strong>Return</strong></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>