import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { ProductService } from '../services/product.service';
import { Products } from '../services/products';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  itemsCount = 0;

  subscription: Subscription;

  constructor(private productService: ProductService, private dataService: DataService) { }

  ngOnInit(): void {
    this.subscription = this.dataService.currentItemsCount
      .subscribe((count) => this.itemsCount = count);
    this.getItemsInCart();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getItemsInCart(): void {
    this.productService.getProductsInCart()
      .subscribe((data: any) => {
        this.itemsCount = data.length;
        this.dataService.changeItemsCount(this.itemsCount);
      });
  }
}
