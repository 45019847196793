<div class="container my-3 home_page">
  <div class="row my-3">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
      <div class="section-title">
        <h3 class="title">{{title}}</h3>
      </div>
    </div>

    <div *ngIf="response"> 
      <div class="col-md-12">
        <div class="row my-1">
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 my-1 products"
             *ngFor="let product of response.products">
            <div class="card">
              <div class="d-flex align-items-center mx-auto my-2 img-product-box"><img class="card-img-top img-product"
                  src="{{product.image}}" alt="Product image" />
              </div>
              <a routerLink="/product/{{product.product_id}}">
                <div class="card-header">
                  <div class="card-title">
                    <div class="product-title">{{product.product_title}}</div>
                  </div>
                </div>
              </a>
              <div class="card-body">
                <p class="lead" style="text-align: center; margin-bottom: 0">
                  {{addSpacesToPrice(product.price.slice(0, -3))}}</p>
              </div>
              <div class="card-body row border-primary">
                <div class="col-md-6">
                  <button class="btn btn-outline-primary btn-sm btn-block" (click)="addToCart(product.product_id)">Add
                    to cart</button>
                </div>
                <div class="col-md-6">
                  <a class="btn btn-outline-primary btn-sm btn-block" (click)="buyNow(product.product_id)">Buy now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>