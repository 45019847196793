import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ProductComponent } from './components/product/product.component';
import { CartComponent } from './components/cart/cart.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { PurchasesComponent } from './components/purchases/purchases.component';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { RefundsComponent } from './components/refunds/refunds.component';
import { WithdrawalComponent } from './components/withdrawal/withdrawal.component';
import { ReturnConfirmationComponent } from './components/return-confirmation/return-confirmation.component';
import { AdminComponent } from './components/admin/admin.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'product/:id',
    component: ProductComponent,
  },
  {
    path: 'cart',
    component: CartComponent,
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
  },
  {
    path: 'purchases',
    component: PurchasesComponent,
  },
  {
    path: 'confirmation',
    component: ConfirmationComponent,
  },
  {
    path: 'refunds',
    component: RefundsComponent
  },
  {
    path: 'withdrawal',
    component: WithdrawalComponent,
  },
  {
    path: 'returnconfirmation',
    component: ReturnConfirmationComponent
  },
  {
    path: "toggleadmin",
    component: AdminComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
