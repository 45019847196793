import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { StyleService } from '../services/style.service';
import { Style } from '../services/style';

declare let Brite: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit, OnDestroy {
  price = 0;
  styleId: number;
  style: Style;
  environmentId: number;

  navigatedToConfirmation = false;
  purchaseCompleted = false;

  priceSubscription: Subscription;
  styleIdSubscription: Subscription;
  environmentIdSubscription: Subscription;

  constructor(
    private router: Router,
    private dataService: DataService,
    private styleService: StyleService
  ) {
    this.loadScripts();
  }

  ngOnInit(): void {
    this.priceSubscription = this.dataService.currentPrice.subscribe((price) => this.price = price);
    this.styleIdSubscription = this.dataService.currentStyleId.subscribe((styleId) => this.styleId = styleId);
    this.environmentIdSubscription = this.dataService.currentEnvironmentId.subscribe((environmentId) => this.environmentId = environmentId);

    console.log(`price: ${this.price}`);
    if (this.price > 0) {
      let styleObservable = this.styleService.getStyle(this.styleId);

      forkJoin([styleObservable]).subscribe(data => {
        this.style = data[0];
        console.log(JSON.stringify(this.style));

        console.log("environment ID: " + this.environmentId);

        this.showClient();
      });
    }
  }

  ngOnDestroy(): void {
    this.priceSubscription.unsubscribe();
    this.styleIdSubscription.unsubscribe();
  }

  loadScripts() {
    const dynamicScripts = ['https://client.britepaymentgroup.com/client.js'];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.async = false;
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }

  showClient(): void {
    console.log('TEST new CLIENT');

    const that = this;

    fetch(
      new Request('api/market/order_create', {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' }),
      })
    )
      .then((resp) => resp.json())
      .then((order_response) => {
        console.log(order_response);
        fetch(
          new Request(
            `api/market/order_start_payment/${order_response.order_id}`,
            {
              method: 'GET',
              headers: new Headers({
                'Content-Type': 'application/json',
              }),
            }
          )
        )
          .then((resp) => resp.json())
          .then((create_order_response) => {
            const client = new Brite(create_order_response.token);
            client.start(
              {
                selector: '#BritePayment',
                style: this.style.style_json
              },
              (state) => {
                if (state == Brite.STATE_COMPLETED) {
                  if (!that.purchaseCompleted) {
                    that.purchaseCompleted = true;
                    console.log(`api/market/order_complete_payment/${order_response.order_id}`);
                    fetch(
                      new Request(`api/market/order_complete_payment/${order_response.order_id}`, {
                        method: 'GET',
                        headers: new Headers({ 'Content-Type': 'application/json' })
                      })
                    )
                      .then(() => {
                        console.log('order_complete_payment');
                        that.onClientCompleted();
                      });
                  }
                }
              },
              () => {
                console.log('client closed');
                client.stop();
                that.goToPurchases();
              }
            );
          });
      });
  }


  goToPurchases(): void {
    if (!this.navigatedToConfirmation) {
        this.navigatedToConfirmation = true;
        console.log('goToPurchases');
        console.log(Date.now() / 1000);
        this.router.navigateByUrl('/confirmation');
    }
  }

  onClientCompleted(): void {
    console.log('onClientCompleted');
    console.log(Date.now() / 1000);
    setTimeout(() => {
      this.goToPurchases();
    }, 10 * 1000);
  }
}
