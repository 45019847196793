<div class="m-3  return-confirmation">
    <div class="row p-4">
        <div class="col-xs-12 text-left py-2">
            <h5 class="page-header">Return Confirmation</h5>
        </div>
    </div>
    <div class="row">
        <div class=" col-lg-8 offset-lg-2">
            <div class=" col-xl-12 text-center py-2">
                <h5><strong>Your Order Number <strong>{{order_id}}</strong> has been returned</strong></h5>
            </div>


            <div class="row order-confirmation-button-container d-flex justify-content-center border-primary my-3 py-5">
                <div class="col-md-6">
                    <a routerLink="/" class="btn btn-outline-primary btn-sm btn-block">
                        <strong> Home Page</strong></a>
                </div>
                <div class="col-md-6">
                    <a routerLink="/purchases" class="btn btn-outline-primary btn-sm btn-block">
                        <strong>View your orders</strong></a>
                </div>
            </div>
        </div>
    </div>
</div>