<div class="container my-3 product_details" *ngIf="product">
  <div class=" row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-5 col-sm-6 col-12 my-3">
          <div class="d-flex align-items-center justify-content-center img-product-details-box">
            <img class="img-product-details" src="{{product.image}}" alt="Product image" />
          </div>
        </div>

        <div class="col-md-7 col-sm-6 col-12 my-3">
          <h5 class="product-title">{{product.product_title | uppercase}}</h5>
          <p class="mb-2 text-muted text-uppercase small">Electronics</p>
          <div class="price">
            <h3 class="product-price"><strong>{{addSpacesToPrice(product.price.slice(0, -3))}}</strong></h3>
          </div>
          <div class="product-overview">
            <label class="text-muted text-uppercase small">Description</label>
            <p class="product-description w-100">
              {{product.description}}
            </p>
          </div>
          <hr />

          <div class="product-options-container">
            <div class="quantity-button">
              <button type="button" class="button" (click)="minus()">
                <i class="fa fa-minus" style="font-size: 10px"></i>
              </button>
              <input type="number" class="input-form" min=1 [value]="quantity" style="width: 10%" />
              <button type="button" class="button" (click)="plus()">
                <i class="fa fa-plus" style="font-size: 10px"></i>
              </button>
            </div>
          </div>

          <hr />

          <div class="product-buttons-container">
            <div class="add-to-cart my-3">
              <a class="btn btn-outline-primary btn-sm btn-block" (click)="addToCart(product.product_id)">Add to
                cart</a>
            </div>

            <div class=" buy-now my-3">
              <a class="btn btn-outline-primary btn-sm btn-block" (click)="buyNow(product.product_id, product.price)">
                Buy now
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>