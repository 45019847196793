import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from '../services/data.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-return-confirmation',
  templateUrl: './return-confirmation.component.html',
  styleUrls: ['./return-confirmation.component.scss']
})
export class ReturnConfirmationComponent implements OnInit {
  order_id: number;

  subscription: Subscription;

  constructor(private dataService: DataService, private productService: ProductService) { }

  ngOnInit(): void {
    this.subscription = this.dataService.currentOrderReturn.subscribe((returnedOrder) => this.order_id = returnedOrder);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  returnOrder(order_id: number): void {
    console.log(`order_return id: ${order_id.toString()}`);
    this.productService.returnOrder(order_id)
      .subscribe((response: any) => {
      });
  }
}
