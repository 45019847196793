import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { StyleService } from '../services/style.service';
import { Style } from '../services/style';

declare let Brite: any;

@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss']
})
export class WithdrawalComponent implements OnInit, OnDestroy {
  returnedOrder: number;
  subscription: Subscription;
  styleId: number;
  style: Style;

  navigatedToReturnConfirmation = false;
  moneyReturned = false;

  styleIdSubscription: Subscription;

  constructor(
    private dataService: DataService,
    private router: Router,
    private styleService: StyleService
  ) {
    this.loadScripts();
  }

  ngOnInit(): void {
    this.subscription = this.dataService.currentOrderReturn.subscribe((returnedOrder) => this.returnedOrder = returnedOrder);
    this.styleIdSubscription = this.dataService.currentStyleId.subscribe((styleId) => this.styleId = styleId);

    let styleObservable = this.styleService.getStyle(this.styleId);

    styleObservable.subscribe((data: Style) => {
      this.style = data;
      console.log(JSON.stringify(this.style));

      this.showClient();
    });

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.styleIdSubscription.unsubscribe();
  }

  loadScripts() {
    const dynamicScripts = ['https://client.britepaymentgroup.com/client.js'];

    for (let i = 0; i < dynamicScripts.length; i++) {
      const node = document.createElement('script');
      node.src = dynamicScripts[i];
      node.async = false;
      document.getElementsByTagName('body')[0].appendChild(node);
    }
  }

  showClient(): void {
    console.log('TEST return money to other bank account');
    const that = this;

    fetch(
      new Request('api/market/select_bank_account', {
        method: 'GET',
      })
    )
      .then((resp) => resp.json())
      .then((select_bank_account_response) => {
        const client = new Brite(select_bank_account_response.token);
        client.start(
          {
            selector: '#BritePayment',
            style: this.style.style_json
          },
          (state) => {
            if (state == Brite.STATE_COMPLETED) {
              if (!that.moneyReturned) {
                that.moneyReturned = true;
                that.returnMoneyToOtherAccount(select_bank_account_response.token);
              }
            }
          },

          () => {
            console.log('client ReturnMoneyToOtherAccount and then closed');
            client.stop();
            if (!that.moneyReturned) {
              that.moneyReturned = true;
              that.returnMoneyToOtherAccount(select_bank_account_response.token);
            }
          }
        );
      });
  }

  returnMoneyToOtherAccount(token: any): void {
    const that = this;

    fetch(
      new Request(`api/market/get_bank_account/${token}`, {
        method: 'GET',
        headers: new Headers({ 'Content-Type': 'application/json' })
      })
    )
      .then((resp) => resp.json())
      .then((get_bank_account_response) => {
        fetch(
          new Request(`api/market/order_return/${that.returnedOrder}/${get_bank_account_response.bank_account_id}`, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json' })
          })
        )
          .then(() => {
            console.log('order_return');
            that.goToReturnConfirmation();
          });
      });
  }

  goToReturnConfirmation(): void {
    if (!this.navigatedToReturnConfirmation) {
      this.navigatedToReturnConfirmation = true;
      console.log('goToReturnConfirmation');
      console.log(Date.now() / 1000);
      this.router.navigateByUrl('/returnconfirmation');
    }
  }
}
