import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { PurchasesService } from '../services/purchases.service';
import { Order, Orders } from '../services/order';
import { DataService } from '../services/data.service';

@Component({
  selector: 'app-purchases',
  templateUrl: './purchases.component.html',
  styleUrls: ['./purchases.component.scss'],
  providers: [PurchasesService]

})
export class PurchasesComponent implements OnInit {
  orders: Orders;

  constructor(
    private purchasesService: PurchasesService, private dataService: DataService
  ) { }

  calculateOrderPrices(order_id: number): number {
    const { items } = this.orders[order_id];
    return items.reduce((accumulator, item) => accumulator + +item.price, 0);
  }

  addSpacesToPrice(price: number): string {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }

  convertOrderStatus(status: number): string {
    switch (status) {
      case 1:
        return 'Placed';
      case 2:
        return 'Paid';
      case 3:
        return 'Returned';
      default:
        throw 'Invalid order status';
    }
  }

  ngOnInit(): void {
    this.getOrderList();
  }

  getOrderList(): void {
    this.purchasesService.getOrderList()
      .subscribe((data: Orders) => {
        console.log(data);
        Object.values(data).forEach((value) => {
          value.created = new Date(value.created).toLocaleString();
        });
        this.orders = data;
      });
  }

  keyDescOrder = (a: KeyValue<number, Order>, b: KeyValue<number, Order>): number => a.key > b.key ? -1 : b.key > a.key ? 1 : 0

  passOrderId(orderId: number): void {
    this.dataService.changeOrderReturn(orderId);
  }
}
