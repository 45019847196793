<footer>
  <div class="container">
    <div class="row">
      <div class="col-sm-4 footer_category">
        <h6>Customer service</h6>
        <div class="footer_category-list">
          <a href="https://britepaymentgroup.com/">Contact us</a>
        </div>
        <div class="footer_category-list">
          <a href="https://britepaymentgroup.com/support/">Support</a>
        </div>
      </div>

      <div class="col-sm-4 footer_category">
        <h6>Information</h6>
        <div class="footer_category-list">
          <a href="https://britepaymentgroup.com/about-us/">About us</a>
        </div>
        <div class="footer_category-list">
          <a href="https://brite.teamtailor.com/">Career</a>
        </div>
      </div>

      <div class="col-sm-4 footer_category">
        <h6>Our products</h6>
        <div class="footer_category-list">
          <a href="https://britepaymentgroup.com/instant-payments/">Instant payments</a>
        </div>
        <div class="footer_category-list">
          <a href="https://britepaymentgroup.com/instant-withdrawals/">Instant withdrawals</a>
        </div>

        <div class="footer_category-list">
          <a href="https://britepaymentgroup.com/kyc/">KYC</a>
        </div>
      </div>
    </div>

    <div class="row footer_copyright">
      <div class="col-md-6 col-lg-8 mx-auto">
        <ul class="list-inline text-center">
          <li class="list-inline-item">
            <a href="https://se.linkedin.com/company/brite-ab" class="footer_social-links" title="Linkedin">
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-linkedin fa-stack-1x fa-inverse"></i>
              </span>
            </a>
          </li>
          <li class="list-inline-item">
            <a href="https://www.facebook.com/BritePaymentGroup/" class="footer_social-links" title="Facebook">
              <span class="fa-stack fa-lg">
                <i class="fa fa-circle fa-stack-2x"></i>
                <i class="fa fa-facebook-f fa-stack-1x fa-inverse"></i>
              </span>
            </a>
          </li>
        </ul>
        <p class="copyright text-center text-muted">
          Copyright © 2020-2021 Brite AB. All rights reserved.
        </p>
      </div>
    </div>
  </div>
  <div class="row toggle">
    <div class="col-md-1 offset-md-11 text-center">
      <a class="nav-item nav-link cog-icon" routerLink="/toggleadmin">
        <i style='font-size:20px' class='fa fa-cog'></i>
      </a>
    </div>
  </div>
</footer>