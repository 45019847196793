import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Product, Products } from './products';


@Injectable({
  providedIn: 'root'
})

export class ProductService {
  private productUrl = '/api/market';

  constructor(private httpClient: HttpClient) { }


  getProducts() {
    return this.httpClient.get<Products>(this.productUrl)
      .pipe(
        catchError(this.handleError)
      );
  }

  getProductDetails(id: number) {
    return this.httpClient.get<Product>(`${this.productUrl}/product/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  addToCart(id: number) {
    console.log(`ProductService addToCart id: ${id.toString()}`);
    return this.httpClient.get(`${this.productUrl}/cart_add_item/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  getProductsInCart() {
    console.log('getProductsInCart');
    return this.httpClient.get<Products>(`${this.productUrl}/cart_get_items`)
      .pipe(
        catchError(this.handleError)
      );
  }

  flushCart() {
    console.log('flushCart');
    return this.httpClient.get(`${this.productUrl}/cart_flush`)
      .pipe(
        catchError(this.handleError)
      );
  }

  removeFromCart(id: number) {
    console.log(`removeFromCart id: ${id.toString()}`);
    return this.httpClient.get(`${this.productUrl}/cart_remove_item/${id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  returnOrder(order_id: number) {
    console.log(`order_return id: ${order_id.toString()}`);
    return this.httpClient.get(`${this.productUrl}/order_return/${order_id}`)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, `
        + `body was: ${error.error}`
      );
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.'
    );
  }
}

