<div class="containter m-3 ordel-cancellation-page">
    <div class="row p-4">
        <div class="col-xs-12 text-left py-2">
            <h5 class="page-header">Order Cancellation</h5>
        </div>
    </div>
    <div class="row ordel-cancellation-datails">
        <div class="col-lg-8 offset-lg-2 text-center py-2">
            <div class="row">
                <div class="col-xl-12">
                     <h4>You can return the money for the Order Number <strong>{{order_id}}</strong> to your
                        <strong>current
                            bank account</strong>, that you used to pay for the purchase, or select <strong>other bank
                            account</strong>.
                    </h4>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-12">
                    <h5>Please select the account to which you want to get the money back.
                    </h5>
                    <p>Information about withdrawal will be sent to your email once your withdrawal has been
                        processed.
                    </p>
                </div>
            </div>
            <div class="row d-flex my-3 py-5">
                <div class="col-md-6 border-primary">
                    <a routerLink="/returnconfirmation" class="btn btn-outline-primary btn-lg btn-block h-100"
                        (click)="returnOrder(order_id)">
                        <strong>Return money to your current bank account</strong></a>
                </div>
                <div class="col-md-6 border-primary">
                    <a routerLink="/withdrawal" class="btn btn-outline-primary btn-lg btn-block h-100"
                        (click)="returnMoneytoOtherAccount(order_id)">
                        <strong>Return money to other bank account</strong></a>
                </div>
            </div>
        </div>
    </div>
</div>