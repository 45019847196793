<div class="m-3 thank-you-page">
    <div class="row p-4">
        <div class="col-xs-12 text-left py-2">
            <h5 class="page-header">Order Confirmation</h5>
        </div>
    </div>
    <div class="row">
        <div class="thank-you-purchase col-lg-8 offset-lg-2">
            <div class="thank-you-text col-xl-12 text-center py-2">
                <h3><strong>Thank you for your purchase!</strong></h3>
            </div>
            <div class="confirmation-email col-xl-12">
                <p>Your order has been placed and is being processed. A confirmation email will be sent to your email.
                    You will
                    receive another email with tracking information once your order has shipped.</p>
            </div>
            <div class="row order-confirmation-button-container d-flex justify-content-center border-primary my-3 py-5">
                <div class="col-md-6">
                    <a routerLink="/" class="btn btn-outline-primary btn-sm btn-block">
                        <strong>Home Page</strong></a>
                </div>
                <div class="col-md-6">
                    <a routerLink="/purchases" class="btn btn-outline-primary btn-sm btn-block">
                        <strong>View your orders</strong></a>
                </div>
            </div>
        </div>
    </div>
</div>